<template>
  <div class="add-invoice">
    <PageHeader is-router title="添加发票抬头"></PageHeader>
    <el-form
      class="invoice-form"
      ref="invoiceFormRef"
      :rules="formRules"
      :model="form"
      inline-message
      size="small"
      :hide-required-asterisk="true"
      label-position="right"
      label-width="290px"
    >
      <!-- <el-form-item prop="type">
        <template #label>
          <form-label required label="抬头类型"></form-label>
        </template>
        <el-button
          :class="{ selected: invoiceType === INVOICE_TYPE.company }"
          @click="handleChangeType(INVOICE_TYPE.company)"
          round
          size="small"
        >
          企业
        </el-button>
        <el-button
          :class="{ selected: invoiceType === INVOICE_TYPE.person }"
          @click="handleChangeType(INVOICE_TYPE.person)"
          round
          size="small"
        >
          个人
        </el-button>
      </el-form-item> -->
      <template v-if="invoiceType === INVOICE_TYPE.person">
        <el-form-item prop="username">
          <template #label>
            <form-label required label="个人姓名"></form-label>
          </template>
          <el-input v-model.trim="form.username" placeholder="填写个人名字"></el-input>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="companyName">
          <template #label>
            <form-label required label="发票抬头"></form-label>
          </template>
          <el-input v-model.trim="form.companyName" placeholder="填写企业名称"></el-input>
        </el-form-item>
        <el-form-item prop="dutyParagraph">
          <template #label>
            <form-label required label="企业税号"></form-label>
          </template>
          <el-input v-model.trim="form.dutyParagraph" placeholder="填写企业税号"></el-input>
        </el-form-item>
        <el-form-item prop="address">
          <template #label>
            <form-label label="企业地址"></form-label>
          </template>
          <el-input v-model.trim="form.address" placeholder="填写企业地址"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <template #label>
            <form-label label="企业电话"></form-label>
          </template>
          <el-input v-model.trim="form.phone" placeholder="填写企业号码"></el-input>
        </el-form-item>
        <el-form-item prop="bank">
          <template #label>
            <form-label label="开户银行"></form-label>
          </template>
          <el-input v-model="form.bank" placeholder="填写开户银行"></el-input>
        </el-form-item>
        <el-form-item prop="bankNumber">
          <template #label>
            <form-label label="银行账号"></form-label>
          </template>
          <el-input v-model="form.bankNumber" placeholder="填写银行账号"></el-input>
        </el-form-item>
      </template>
      <section class="form-section">
        <div class="form-section-title">收票人信息</div>
        <el-form-item prop="userPhone">
          <template #label>
            <form-label label="收票人手机"></form-label>
          </template>
          <el-input v-model="form.userPhone" placeholder="填写收票人手机"></el-input>
        </el-form-item>
        <el-form-item prop="userEmail">
          <template #label>
            <form-label label="收票人邮箱"></form-label>
          </template>
          <el-input v-model.trim="form.userEmail" placeholder="填写收票人邮箱"></el-input>
        </el-form-item>
      </section>
      <div class="btn-wrap">
        <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
        <el-button size="small" @click="onCancel">取消</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import PageHeader from '@/views/components/page-header/index.vue'
import FormLabel from '@/views/components/form-label'
import { nextTick, reactive, ref } from 'vue'
import {
  emailValidator,
  bankNumberValidator,
  mobildValidator,
  mobileAndPhoneValidator,
  creditCodeValidator
} from '@/common/validator'
import { getInvoiceTitle, saveInvoiceTitle, updateInvoiceTitle } from '@/apis'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useUser } from '@/hooks'
const INVOICE_TYPE = {
  company: 1,
  person: 2
}
export default {
  name: 'addInvoice',
  components: { PageHeader, FormLabel },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params
    const { user } = useUser()
    const invoiceType = ref(INVOICE_TYPE.company)
    const invoiceFormRef = ref(null)
    const loading = ref(false)
    const handleChangeType = type => {
      invoiceType.value = type
      nextTick(() => {
        invoiceFormRef.value.clearValidate()
      })
    }
    const form = reactive({
      username: '',
      companyName: '',
      dutyParagraph: '', // 企业税号
      address: '', //企业地址
      phone: '', // 企业电话
      bank: '', // 企业银行
      bankNumber: '', // 银行账号
      userPhone: '', // 收票人手机
      userEmail: '' // 收票人邮箱
    })
    const formRules = {
      username: { required: true, message: '请输入个人姓名', trigger: ['blur', 'change'] },
      companyName: [
        { required: true, message: '请输入发票抬头', trigger: ['blur', 'change'] },
        { max: 50, message: '最长50个字', trigger: ['blur', 'change'] }
      ],
      dutyParagraph: [
        { required: true, message: '请输入企业税号', trigger: ['blur', 'change'] },
        {
          validator: creditCodeValidator,
          message: '企业税号格式不正确',
          trigger: ['blur', 'change']
        }
      ],
      bankNumber: [
        {
          validator: bankNumberValidator,
          message: '银行卡号格式不正确',
          trigger: ['blur', 'change']
        }
      ],
      phone: [
        {
          validator: mobileAndPhoneValidator,
          message: '电话格式不正确',
          trigger: ['blur', 'change']
        }
      ],
      userPhone: [
        {
          validator: mobildValidator,
          trigger: ['blur', 'change']
        }
      ],
      userEmail: [
        // { required: true, message: '请输入收票人邮箱', trigger: ['blur', 'change'] },
        { validator: emailValidator, trigger: ['blur', 'change'] }
      ]
    }
    const initInvoiceInfo = async () => {
      try {
        const result = await getInvoiceTitle({ id })
        const { data } = result || {}
        form.companyName = data.companyName
        form.dutyParagraph = data.companyDuty
        form.address = data.companyAddress
        form.userEmail = data.ticketEmail
        form.userPhone = data.ticketMobile
        form.phone = data.companyTel
        form.bank = data.bankType
        form.bankNumber = data.bankNo
      } catch (error) {
        console.log('initInvoiceInfo error', error)
      }
    }
    if (id) {
      initInvoiceInfo()
    }

    // 执行网络请求
    const handleRequest = async params => {
      try {
        if (id) {
          params['id'] = id
          await updateInvoiceTitle(params)
          ElMessage.success('更新成功')
        } else {
          await saveInvoiceTitle(params)
          ElMessage.success('添加成功')
        }
        router.back()
        loading.value = false
      } catch (error) {
        loading.value = false
      }
    }
    const onSubmit = () => {
      invoiceFormRef.value.validate(valid => {
        if (!valid) return false
        loading.value = true
        let params = {}
        if (invoiceType.value === INVOICE_TYPE.person) {
          params = {
            titleType: invoiceType.value,
            personalName: form.username,
            ticketMobile: form.userPhone,
            ticketEmail: form.userEmail
          }
        } else {
          params = {
            titleType: invoiceType.value,
            companyNo: user.value.companyNo,
            companyName: form.companyName,
            companyDuty: form.dutyParagraph,
            companyAddress: form.address,
            companyTel: form.phone,
            bankType: form.bank,
            bankNo: form.bankNumber,
            ticketMobile: form.userPhone,
            ticketEmail: form.userEmail
          }
        }
        handleRequest(params)
      })
    }
    const onCancel = () => {
      // 重置表单
      invoiceFormRef.value.resetFields()
    }
    return { form, invoiceFormRef, formRules, invoiceType, INVOICE_TYPE, handleChangeType, onSubmit, onCancel, loading }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
