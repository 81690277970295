import { h } from 'vue'
const hasKey = (obj, key) => {
  return key in obj
}
const formLabel = (props, context) => {
  return [
    h('span', { ...context.attrs }, props.label || ''),
    hasKey(props, 'required') && h('i', { class: 'form-label-dot red-color' }, '*'),
    ':'
  ]
}
export default formLabel
